










import { Component, Prop, Vue, Watch, Mixins } from 'vue-property-decorator';
// @ts-ignore
import InternalChartDoughnut from '@/components/InternalChartDoughnut';
import { merge } from 'typescript-object-utils';

@Component({
  components: {
    InternalChartDoughnut,
  },
})
export default class GaugeChart extends Vue {
  @Prop() public data!: any;
  @Prop() public options!: any;

  public chartWidth: number = 90;
  public chartHeight: number = 90;
  public loaded: boolean = false;

  public chartOptions(): object {
    const baseoptions = {
    };
    return merge(baseoptions, this.options);
  }

  public chartData(): object {
    return this.data;
  }

  @Watch('data')
  public onDataChanged() {
    this.loaded = true;
  }

  public mounted() {
    if (this.data.datasets) {
      this.loaded = true;
    }
  }
}
