



























































































































































































import '@/components/Vue2Leaflet';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import CCGraph from '../components/CCGraph.vue';
import GaugeChart from '../components/GaugeChart.vue';
import date from 'quasar/src/utils/date.js';;
import { i18n } from '../i18n';
const { addToDate } = date;

@Component({
  components: {
    CCGraph,
    GaugeChart,
  },
})
export default class AirQualityMobility extends Vue {
  @Prop() public id!: number;

  public $route: any;
  public $router: any;
  public $q: any;
  public $store: any;

  public loading: boolean = false;
  // FIXME found on https://leaflet-extras.github.io/leaflet-providers/preview/
  private mapUrl: string = 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png';
  private mapZoom = 8;
  private mapCenter = [50.6679, 3.14077];
  private analyzers: any = [];
  private analyzer: any = null;
  private analyzerData: any = null;
  private analyzerLocation: any = null;
  private showGraph: boolean = false;
  private analyzerBattery: any = null;
  private indicatorsOrder: any = ['co2', 'temperature', 'humidity', 'co', 'cov', 'no2', 'pm25'];
  private indicators: any = [
    {
      value: 'co2', withUnit: 'co2:ppm',
      label: i18n.t('FIELD_CO2'),
      css: 'wwicon-Picto-CO2',
    }, {
      value: 'temperature', withUnit: 'temperature:celsius',
      label: i18n.t('FIELD_TEMPERATURE'),
      css: 'wwicon-Picto-T°',
    }, {
      value: 'humidity', withUnit: 'humidity:percent',
      label: i18n.t('FIELD_HUMIDITY'),
      css: 'wwicon-Picto-H',
    }, {
      value: 'co', withUnit: 'co:mgm3',
      label: i18n.t('FIELD_CO'),
      css: 'wwicon-Picto-CO',
    }, {
      value: 'cov', withUnit: 'cov:ppb',
      label: i18n.t('FIELD_COV'),
      css: 'wwicon-Picto-COV',
    }, {
      value: 'no2', withUnit: 'no2:ppb',
      label: i18n.t('FIELD_NO2'),
      css: 'wwicon-Picto-NO2',
    }, {
      value: 'pm25', withUnit: 'pm25:ugm3',
      label: i18n.t('FIELD_PM25'),
      css: 'wwicon-Picto-PM',
    },
  ];
  private indicatorsUnit: any = {
    co2: 'ppm',
    co: '',
    cov: 'ppb',
    no2: 'ppb',
    temperature: '°C',
    humidity: '%',
    pm25: 'µg/m³',
  };
  private indicator: string = 'co2';

  private dateRanges: any = [
      {value: 'realtime', label: i18n.t('DR_REALTIME')},
      {value: 'today', label: i18n.t('DR_TODAY')},
      {value: 'yesterday', label: i18n.t('DR_YESTERDAY')},
      {value: '7days', label: i18n.t('DR_LAST_7DAYS')},
      {value: '30days', label: i18n.t('DR_LAST_30DAYS')},
      {value: 'thisyear', label: i18n.t('DR_THIS_YEAR')},
      // {value: 'custom', label: 'Personnalisé'},
  ];
  private dateRangeValue: string = 'realtime';
  private dateRangeCustomFrom: any = null;
  private dateRangeCustomTo: any = null;
  private analyzerOpened: boolean = false;
  private analyzerLoading: boolean = false;
  private realtimeIntervalId: any = null;

  private gaugeChartOptions: any = {
    cutoutPercentage: 90,
    circumference: Math.PI * 1.5,
    rotation: -Math.PI * 1.25,
    tooltips: {
      enabled: false,
    },
    legend: {
      display: false,
    },
  };

  public async created() {
    this.loadDateRangeValue();
    this.refreshAnalyzers();
    if (this.id) {
      this.loadAnalyzer(this.id);
    }
  }

  public beforeDestroy() {
    if (this.realtimeIntervalId !== null) {
      clearInterval(this.realtimeIntervalId);
      this.realtimeIntervalId = null;
    }
  }

  public ensureRefreshInterval() {
    if (this.dateRangeValue === 'realtime') {
      if (this.realtimeIntervalId === null) {
        this.realtimeIntervalId = setInterval(this.refresh, 60000);
      }
    } else {
      if (this.realtimeIntervalId === null) {
        clearInterval(this.realtimeIntervalId);
        this.realtimeIntervalId = null;
      }
    }
  }

  @Watch('dateRangeValue')
  private saveDateRangeValue() {
    localStorage.setItem('outdoorDateRange', JSON.stringify({
      value: this.dateRangeValue,
    }));
  }

  private loadDateRangeValue() {
    let outdoorDateRange: any = localStorage.getItem('outdoorDateRange');
    if (outdoorDateRange !== undefined && outdoorDateRange !== null) {
      outdoorDateRange = JSON.parse(outdoorDateRange);
      this.dateRangeValue = outdoorDateRange.value;
    }
  }

  private loadAnalyzer(analyzerId: any) {
    this.analyzerBattery = null;
    this.$store.dispatch(
      'loadAnalyzer', {analyzerId},
    ).then((response: any) => {
      this.analyzer = response;
      this.refreshLast();
      this.refreshLocation();
    });

    this.$store.dispatch(
      'loadAnalyzerBatteryLast', {analyzerId},
    ).then((response: any) => {
      this.analyzerBattery = response.data.value;
    });
  }

  private refreshAnalyzers() {
    this.$store.dispatch('loadAnalyzers', {
      params: {
        outdoor: true,
      },
    })
    .then((response: any) => {
      this.loading = false;
      this.analyzers = response;
    }).catch((error: any) => {
      this.loading = false;
      this.globalError(error);
    });
  }

  @Watch('dateRangeValue')
  private refreshLast() {
    this.$store.dispatch('loadAnalyzerLastData', {
      analyzerId: this.analyzer.id,
      params: {
        indicators: [
          'co2:ppm',
          'cov:ppb',
          'no2:ppb',
          'pm25:ugm3',
          'temperature:celsius',
          'humidity:percent',
          'co:mgm3',
        ],
      },
    })
    .then((response: any) => {
      this.updateAndSetAnalyzerData(response);
    }).catch((error: any) => {
      this.globalError(error);
    });
  }

  private updateAndSetAnalyzerData(response: any) {
    const data = {};
    const thresholds = response.info.thresholds;
    const colorNormal = '#87c7c8';
    const colorWarning = '#E5B398';
    const colorCritical = '#F4A49F';
    const colorWarningFaded = '#E5B39888';
    const colorCriticalFaded = '#F4A49F88';
    const colorHidden = '#f0f0f0';

    let warning: any;
    let critical: any;
    let value: any;
    let maxvalue: any;

    for (const indicator of response.info.indicators) {
      if (thresholds[indicator] !== undefined) {
        warning = thresholds[indicator].warning;
        critical = thresholds[indicator].critical;
        maxvalue = critical + 1;
        value = response.data[indicator];
      } else {
        warning = undefined;
        critical = undefined;
        maxvalue = 10;
        value = 1;
      }

      if (warning !== undefined) {
        if (value >= maxvalue) {
          data[indicator] = {
            data: [value],
            backgroundColor: [colorCritical],
            css: 'critical',
          };
        } else if (value >= critical) {
          data[indicator] = {
            data: [value, maxvalue - value],
            backgroundColor: [colorCritical, colorCriticalFaded],
            css: 'critical',
          };
        } else if (value >= warning) {
          data[indicator] = {
            data: [value, critical - value, maxvalue - critical],
            backgroundColor: [colorWarning, colorWarningFaded, colorCriticalFaded],
            css: 'warning',
          };
        } else {
          data[indicator] = {
            data: [value, warning - value, critical - warning, maxvalue - critical],
            backgroundColor: [colorNormal, colorHidden, colorWarningFaded, colorCriticalFaded],
            css: '',
          };
        }
      } else {
        data[indicator] = {
          data: [value],
          backgroundColor: [colorNormal],
          css: '',
        };
      }
      data[indicator].value = response.data[indicator];
    }
    this.analyzerData = data;
  }

  private focusIndicator(indicator: string) {
    this.showGraph = true;
    this.indicator = indicator;
  }

  private goNextIndicator() {
    let index = this.indicatorsOrder.indexOf(this.indicator);
    const keys = this.analyzer.indicators.split(',');
    do {
      index = (index + 1) % this.indicatorsOrder.length;
      if (keys.indexOf(this.indicatorsOrder[index]) !== -1) {
        break;
      }
    } while (true);

    this.indicator = this.indicatorsOrder[index];
  }

  private goPreviousIndicator() {
    let index = this.indicatorsOrder.indexOf(this.indicator);
    const keys = this.analyzer.indicators.split(',');
    do {
      index = index - 1;
      if (index < 0) {
        index += this.indicatorsOrder.length;
      }
      if (keys.indexOf(this.indicatorsOrder[index]) !== -1) {
        break;
      }
    } while (true);

    this.indicator = this.indicatorsOrder[index];
  }

  private fixMapSize() {
    const map: any = this.$refs.map;
    map.mapObject.invalidateSize();
  }

  private gotoDashboardAnalyzer(analyzer: any) {
    if (analyzer !== null) {
      this.$router.push({
        name: 'airquality-mobility-id',
        params: { id: analyzer.id },
      });
    } else {
      this.$router.push({
        name: 'airquality-mobility',
      });
    }
  }

  @Watch('$route')
  private refreshFromRoute() {
    if (this.id === undefined || this.id === null) {
      this.setAnalyzer(null);
    } else {
      this.loadAnalyzer(this.id);
    }
  }

  private setAnalyzer(analyzer: any) {
    const map: any = this.$refs.map;
    const mapObject = map.mapObject;
    this.analyzer = analyzer;
    if (this.analyzer !== null) {
      this.refreshLast();
      mapObject.flyTo([analyzer.gps_lat, analyzer.gps_lon], 16);
    }

    this.refreshLocation();
  }

  get indicatorsForCurrentAnalyzer() {
    if (this.analyzer === null) {
      return this.indicators;
    }
    const keys = this.analyzer.indicators.split(',');
    return this.indicators.filter((entry: any) => {
      if (keys.indexOf(entry.value) !== -1) {
        if (this.showGraph) {
          if (this.indicator === entry.value) {
            return entry;
          }
          return;
        }
        return entry;
      }
    });
  }

  private async refresh() {
    if (this.id === undefined || this.id === null) {
      this.loadAnalyzer(this.id);
    }
  }

  @Watch('dateRangeValue')
  private refreshLocation() {
    if (this.analyzer === null) {
      this.analyzerLocation = null;
      return;
    }

    const params = {};
    if (this.dateRangeValue === 'realtime') {
      params['realtime'] = true;
    } else {
      params['realtime'] = false;
      params['dt_start'] = date.formatDate(
          this.dateRangeFrom, 'YYYY-MM-DDTHH:mm:ss.SSS000') + 'Z';
      params['dt_end'] = date.formatDate(
          this.dateRangeTo, 'YYYY-MM-DDTHH:mm:ss.SSS000') + 'Z';
    }
    this.$store.dispatch('loadAnalyzerLocation', {
      analyzerId: this.analyzer.id,
      params,
    })
    .then((response: any) => {
      this.analyzerLocation = response;
    }).catch((error: any) => {
      this.analyzerLocation = null;
      this.globalError(error);
    });
  }

  get dateRangeText() {
    if (this.dateRangeValue === 'custom') {
      //
    } else {
      return this.dateRanges.filter((entry: any) => {
        return entry.value === this.dateRangeValue;
      })[0].label;
    }
  }

  get dateRangeFrom() {
    const drv = this.dateRangeValue;
    // Get UTC date
    // see https://stackoverflow.com/questions/948532/how-do-you-convert-a-javascript-date-to-utc
    const localedate = new Date();
    const utcnow =  new Date(
      Date.UTC(
        localedate.getUTCFullYear(),
        localedate.getUTCMonth(),
        localedate.getUTCDate(),
        localedate.getUTCHours(),
        localedate.getUTCMinutes(),
        localedate.getUTCSeconds(),
      ));

    if (drv === 'realtime') {
      return null;
    } else if (drv === 'today') {
      return date.startOfDate(utcnow, 'day');
    } else if (drv === 'yesterday') {
      return date.startOfDate(addToDate(utcnow, { days: -1 }), 'day');
    } else if (drv === '7days') {
      return date.startOfDate(addToDate(utcnow, { days: -7 }), 'day');
    } else if (drv === '30days') {
      return date.startOfDate(addToDate(utcnow, { days: -30 }), 'day');
    } else if (drv === 'thisyear') {
      return date.startOfDate(addToDate(utcnow, { year: -1 }), 'day');
    } else if (drv === 'custom') {
      return this.dateRangeCustomFrom;
    }
  }

  get dateRangeTo() {
    const drv = this.dateRangeValue;
    if (drv === 'realtime') {
      return null;
    } else if (drv === 'today') {
      return addToDate(this.dateRangeFrom, { days: 1 });
    } else if (drv === 'yesterday') {
      return addToDate(this.dateRangeFrom, { days: 1 });
    } else if (drv === '7days') {
      return addToDate(this.dateRangeFrom, { days: 7 });
    } else if (drv === '30days') {
      return addToDate(this.dateRangeFrom, { days: 30 });
    } else if (drv === 'thisyear') {
      return addToDate(this.dateRangeFrom, { year: 1 });
    } else if (drv === 'custom') {
      return this.dateRangeCustomTo;
    }
  }

  get dateRangeLabelFormat() {
    const drv = this.dateRangeValue;
    if (drv === 'realtime') {
      return 'HH:mm';
    } else if (drv === 'today') {
      return 'HH:mm';
    } else if (drv === 'yesterday') {
      return 'HH:mm';
    } else if (drv === '7days') {
      return 'DD/MM HH';
    } else if (drv === '30days') {
      return 'DD/MM';
    } else if (drv === 'thisyear') {
      return 'MM/YY';
    } else if (drv === 'custom') {
      return 'DD/MM HH:mm';
    }
  }
}
